<template>
<div class="screenHeight">
    <div class="text-center">
        <img src="../assets/BRTopimage-01.png" alt="Balkan roads top image" class="topPictureMobile" />
    </div>
    <section class="login">
        <img src="../assets/BalkanRoadsLogoSimbol.png" alt="Balkan roads top image" class="topPictureTablet" />
        <div class="container">
            <h1 class="headlinePhone pb-5 mb-2">Forgot your password?</h1>
            <h1 class="headlineTablet pb-5 mb-2">Forgot your password?</h1>
            <!-- Ovdje ide alert za wrong username ili password -->
            <form @submit.prevent="loginUser">
                <div class="form-group pb-3">
                    <input type="email" class="form-control" name="mail" placeholder="Email" v-model="email" required>
                </div>

                <div class="form-group text-center">
                    <button type="submit" class="btn btn-secondary w-100 text-uppercase">Request password reset</button>
                </div>
            </form>
            <div class="spacingDiv text-center"><router-link :to="{name: 'SignIn'}" class="signinLink">Return to Sign in</router-link></div>
        </div>
        <div class="positionBottom text-center pb-2">
            Don't have an account? <router-link :to="{name: 'Register'}" class="signinLink">Register here.</router-link>
        </div>
    </section>
</div>
</template>

<script>
import axios from 'axios';
import { LOGIN_URL } from '../config/variables';
import {useToast} from "vue-toastification";
export default {
    setup() {
        const toast = useToast();
        return {toast}
    },
    data() {
        return {
            email: "",
        }
    },
    methods: {
        loginUser() {
            axios.post(LOGIN_URL, {
                    email: this.email,
                })
                .then(() => {
                    this.$router.push({
                        name: 'SignIn',
                    })
                })
                .catch((error) =>{
                    console.log(error);
                    this.toast.error('Error');
                });
        },
    },
}
</script>

<style lang="scss">
.eyeIcon{
    position: absolute;
    right: 16px;
    margin-top: 16px;
    text-decoration: none;

    @media only screen and (min-width: 576px){

    }
}
</style>
